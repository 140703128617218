import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AppConst} from "src/app/App.Const";
import {UserService} from "../../../shared/services/user.service";
import {AuthenticationService} from "../../../shared/services/authentication.service";

declare var require
const Swal = require('sweetalert2')

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loadinBtn = false
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(private readonly auth: AuthenticationService,
    private  userService: UserService,
    private fb: FormBuilder, public router: Router) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]]
    });
  }

  ngOnInit() {
   // this.initSupabase();
  }


  async onSubmit(): Promise<void> {
    this.loadinBtn = true
    try {
      const email = this.loginForm.value.email as string
      const password = this.loginForm.value.password as string
      const data= await this.auth.signIn(this.loginForm.value);

      console.log("data from auth", data);

      if (data?.role === 'ARCELOR') {
        this.router.navigate(["dashboard"]);
      } else {
        this.router.navigate(["dashboard/supplier"]);
      }


      // this.router.navigate([AppConst.MAIN_ROUTES.EMAIL_VERIFICATION])
    } catch (error) {
      console.log("LOGIN ERROR", error);
      return Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: error.message,
      });

    } finally {
      this.loadinBtn = false

    }
  }


  showPassword() {
    this.show = !this.show
  }

}
