import {Routes} from "@angular/router";
import {AppConst} from "src/app/App.Const";
import {AdminGuard} from "../guard/admin.guard";

export const content: Routes = [
  {
    path: AppConst.MAIN_ROUTES.ADMINISTRATOR,
    loadChildren: () => import('../../module/administrator/administrator.module').then(m => m.AdministratorModule),
    canActivate:[AdminGuard]
  },
  {
    path: "volumerequest",
    loadChildren: () => import('../../module/volumerequest/volumerequest.module').then(m => m.VolumerequestModule),
    canActivate:[AdminGuard]
  }
  ,
  {
    path: "dashboard",
    loadChildren: () => import('../../module/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate:[AdminGuard]
  },
  {
    path: "proposal",
    loadChildren: () => import('../../module/proposals/proposal.module').then(m => m.ProposalModule),
    canActivate:[AdminGuard]
  }
  ,
  {
    path: "order",
    loadChildren: () => import('../../module/orders/orders.module').then(m => m.OrdersModule),
    canActivate:[AdminGuard]
  }

  ,
  {
    path: "booking",
    loadChildren: () => import('../../module/bookings/booking.module').then(m => m.BookingModule),
    canActivate:[AdminGuard]
  }
  ,
  {
    path: "delivery",
    loadChildren: () => import('../../module/deliveries/delivery.module').then(m => m.DeliveryModule),
    canActivate:[AdminGuard]
  }
  ,
  {
    path: "carrier",
    loadChildren: () => import('../../module/carriers/carrier.module').then(m => m.CarrierModule),
    canActivate:[AdminGuard]
  }
];
