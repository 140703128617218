import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {SupabaseService} from "../services/supabase.service";
import {UserService} from "../services/user.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  session = this.supabase.session
  constructor(public router: Router,
              public supabase: SupabaseService,
              public userService: UserService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem("username")) {
      return true;
    } else {
      this.router.navigate(["/"]);
    }
  }
}
