import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {Observable, Subject} from 'rxjs';
import {UserService} from "./user.service";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authenticated = false;
  roleId: any;
  companyStatus: number;
  private subject = new Subject<any>();

  constructor(private _router: Router, private _http: HttpClient, private  userService: UserService) {
  }

  async signIn(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(environment.apiUrl + '/public/auth', body)
        .subscribe({
          next: data => {
            console.log(data);
            this.authenticated = true;
            const token: any = data;
            console.log('data', token.token);
            this.roleId = token.role;
            console.log('role_id', token.role);
            // if (this.roleId === 'ADMIN' || this.roleId === 'MANAGER' ) {
            //   this._router.navigateByUrl('/admin/dashboard');
            // } else {
            //   this._router.navigateByUrl('/resume/personal-detail/edit/'+token.id);
            // }
            localStorage.setItem('username', token.email);
            localStorage.setItem('roleId', this.roleId);
            localStorage.setItem('userId', token.id);
            this.saveToken(token.token);
            this.userService.initToken(data);
            resolve(data);
            //this.authService.version='en';
          },
          error: error  => {
            console.log("TOTOTO---<",error);
            reject("invalid credentails")
          },
        });
    });

  }

  saveToken(token) {
    const expireDate = new Date().getTime() + (1000 * token.expires_in);

    // localStorage.setItem('expireDate', expireDate.toString());
    localStorage.setItem('token', token);

    //  this._router.navigate(['/']);
  }

}
