import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from "./shared/shared.module";
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';

// // for HttpClient import:
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
// // for Router import:
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
// // for Core import:
import {LoadingBarModule} from '@ngx-loading-bar/core';

import {AdminGuard} from './shared/guard/admin.guard';
import {CookieService} from 'ngx-cookie-service';

import {AppComponent} from './app.component';
import {LoginComponent} from './module/auth/login/login.component';

import {OverlayModule} from '@angular/cdk/overlay';
import {FilterTableComponent} from './shared/components/filter-table/filter-table.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {EmailVerificationComponent} from './module/auth/email-verification/email-verification.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from "@danielmoncada/angular-datetime-picker";
import {DatepickerModule} from "ng2-datepicker";
import {AppInjector} from "./app-injector.service";
import {CalendarModule} from "primeng/calendar";
import {DialogService} from "primeng/dynamicdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {AlertModalComponent} from './module/alert-modal/alert-modal.component';
import {registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr';
import {ToastModule} from "primeng/toast";

registerLocaleData(localeFr, 'fr-FR');


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FilterTableComponent,
    EmailVerificationComponent,
    AlertModalComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        OverlayModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        NgSelectModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        }),
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        DatepickerModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        CalendarModule,
      ToastModule
    ],
  providers: [AdminGuard,
    CookieService,DialogService,
    MessageService, ConfirmationService,
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
