import {Injectable} from '@angular/core';
import {BaseServiceService} from "./base-service.service";

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseServiceService {
  constructor() {
    super();
  }

  getAllOrderList(): any {
    return this.apiGet("/order");
  }

  getAllOrderListSupplier(): any {
    return this.apiGet("/order/supplier");
  }
  getOrderDetails(orderId): any {
    return this.apiGet("/order/view/"+orderId);
  }

}
