<div class="container-fluid p-0">
  <div class="row g-0">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img width="220" height="35" class="img-fluid for-light" src="assets/images/logo/arcelormitthal.png" alt="looginpage" />
              <img width="220" height="35" class="img-fluid for-dark" src="assets/images/logo/arcelor_dark.png" alt="looginpage" />
            </a>
          </div>
          <div class="login-main">
            <form [formGroup]="loginForm" class="theme-form">
              <h4>Sign in to account</h4>
              <p>Enter your email to login</p>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" formControlName="email" placeholder="example@yourmail.com" required=""
                  type="email" />
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                  class="text text-danger mt-1">Email is required</div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                  class="text text-danger mt-1">Invalid Email</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" formControlName="password" placeholder="" required=""
                       type="password" />
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                     class="text text-danger mt-1">Password</div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.password"
                     class="text text-danger mt-1">Invalid Password</div>
              </div>

              <div class="form-group mb-0">
                <div class="text-end mt-3">
                  <button (click)="onSubmit()" [disabled]="!loginForm.valid" class="btn btn-primary d-block w-100"
                    type="button">
                    <div class="loader-box">
                      <div *ngIf="loadinBtn" class="loader-2"></div>
                      <span>Sign in</span>
                    </div>
                  </button>
                </div>
              </div>
              <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6>
              <div class="social mt-4">
                <div class="btn-showcase">
                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'log-in'" class="txt-google"></app-feather-icons>Google + </a>
                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>Facebook </a>
                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter </a>
                </div>
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
