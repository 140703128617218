import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {SupabaseService} from "../../../../services/supabase.service";
import {environment} from "../../../../../../environments/environment";
import {Observable, of} from "rxjs";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  userEmail:any = '';
  showPasswordEdit: Observable<boolean> = of(false);
  constructor(public router: Router, public supabase: SupabaseService) {
  }

  ngOnInit() {
    let supabaseToken = localStorage.getItem("sb-"+environment.supabaseId+ "-auth-token");
    if (supabaseToken != null)
    {
      this.userEmail = JSON.parse(supabaseToken).user?.email;
      //console.log("token set from local storage")
    }
    else
    {
      this.userEmail = this.supabase?.session?.user?.email;
    }

  }

  logoutFunc() {
    this.supabase.signOut().then(r =>
    {
      localStorage.removeItem("username");
      localStorage.removeItem("roleId");
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      this.router.navigateByUrl('auth/login');
    });
  }

  showPasswordModal(): void
  {
    this.showPasswordEdit = of(true);
  }

  passwordChangeEventHandler($vevent: any) {
    console.log("FORM EVENT-->", $vevent);
  }
}
